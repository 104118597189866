<template>
  <div class="v-login">
    <div class="v-login__wrapper">
      <div class="v-login__cols">
        <div class="v-login__col">
          <VHeading header="h1" class="v-login__heading">
            {{ $t('LOGIN_HEADING')}}
          </VHeading>
          <img class="v-login__img v-login__img--mobile" src="/img/welcome-image.png" alt="" />
          <div v-if="gotNoAccess"
            class="v-login__no-access"
            v-html="$t('LOGIN_NO_ACCESS')"
          />
          <p class="v-login__text" v-html="$t('LOGIN_TEXT')" />
          <div class="v-login__buttons">
            <TheButton
              class="v-login__button"
              @click.native="login"
            >
              {{ $t('LOGIN_STUDENT') }}
            </TheButton>
            <TheButton
              class="v-login__button v-login__button--secondary"
              @click.native="login"
            >
              {{ $t('LOGIN_TEACHER') }}
            </TheButton>
          </div>
        </div>
        <div class="v-login__col">
          <img class="v-login__img v-login__img--desktop" src="/img/welcome-image.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from '@vue/composition-api'
import TheButton from '@forlagshuset/v-button'
import VHeading from '@forlagshuset/v-heading'

import auth from '@/auth'

export default {
  components: {
    TheButton,
    VHeading
  },

  setup (props, ctx) {
    const gotNoAccess = computed(() => ctx.root.$store.getters['appConfig/getNoAccess'])

    onMounted(() => {
      auth.checkToken()
        .then(user => {
          ctx.root.$store.dispatch('appConfig/setNoAccess', false)
          ctx.root.$store.dispatch('user/setToken')
          ctx.root.$store.dispatch('user/setUser', user)
          ctx.root.$router.push('/')
        })
    })

    const login = () => auth.authorize({
      'redirect_url': window.hostname
    })

    return {
      login,
      gotNoAccess,
    }
  }
}
</script>

<style lang="scss">
.v-login {
  min-height: 100vh;
  text-align: left;
  background: $color-medium-grey;
}

.v-login__no-access {
  background: rgba(crimson, .2);
  padding: 1rem;
  border: 1px solid crimson;
  border-radius: .25rem;
}

.v-login__text {
  text-align: center;
  @include bp(medium) {
    text-align: left;
  }
  margin-bottom: 4rem;
  font-size: 1.3rem;
  line-height: 1.7;
  font-family: 'Lyon Display Web', sans-serif;
}

.v-login__buttons {
  display: flex;
  flex-direction: column;
  max-width: 22rem;

    @include bp-down(medium) {
      margin: 0 auto;
    }
}

.v-login__button {
  font-family: CircularPro, sans-serif;
  background: $color-brand;
  color: $color-white;
  padding: 0.7rem 4rem;
  font-size: 1.3rem;
  border-radius: 2rem;
  border: 3px $color-brand solid;
  line-height: normal;

  &--secondary {
    display: inline-block;
    margin-top: 1rem;
    background: transparent;
    color: $color-brand;
  }
}

.v-login__wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem 1.2rem;

  @include bp(medium) {
    padding: 2rem 1rem 4rem;
  }
}

.v-login__img {
  margin-top: 7rem;
  display: none;
  width: 100%;

  &--mobile {
    @include bp-down(medium) {
      margin-top: 2.5rem;
      display: block;
    }
  }
  
  &--desktop {
    @include bp(medium) {
      display: block;
    }
  }
}

.v-login__cols {
  margin-top: 2rem;
  display: flex;
}

.v-login__col {
  padding: 1rem;

  @include bp(medium) {
    width: 50%;
  }
}

.v-login__heading {
  text-align: center;
  @include bp(medium) {
    text-align: left;
    font-size: 1.6rem;
  }
  font-weight: 700;

  h1 {
    @include bp-down(medium) {
      font-size: 2rem;
    }
    @include bp-down(small) {
      font-size: 1.5rem;
    }
    margin-bottom: 0;
  }
}
</style>